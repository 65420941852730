import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Heading from "../components/Heading";
import Hero from "../components/Hero";
import ContactForm from "../forms/ContactForm";
import OrderForm from "../forms/OrderForm";

type orderFormProps = {
};

const orderForm: FunctionComponent<orderFormProps> = (props) => {

    return (
        <>
            <Section className={'py-20'}>
                <Hero
                    outerContainer
                    headline={'Get started with your company'}
                    subheadline={'Fill out the form and we will be in touch with you for further discussion.'}
                />
            </Section>
            <Section className={''}>
                <div className={'w-full md:w-1/2 lg:w-3/5 xl:w-2/5 mx-auto'}>
                    <OrderForm/>
                </div>
            </Section>
        </>
    );
};

export default orderForm;
