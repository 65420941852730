import React, { FunctionComponent, Fragment, useState } from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {FormFieldProps} from "./FormField";
import classNames from "classnames";
import DownArrowIcon from "../assets/icons/ico_downarrow.svg";
import {useField} from "formik";

type FormSelectFieldProps = {
    options: any[];
} & Omit<FormFieldProps, "type" | "LeadingIcon" | "rows">;

const FormSelectField: FunctionComponent<FormSelectFieldProps> = ({
    name,
    label,
    placeholder = 'Select...',
    markRequired = false,
    hint,
    options,
    ...props
}) => {
    const [{value: selected}, {touched, error}, {setValue}] = useField(name);

    const inputClassNames = classNames(
        `group relative pl-4 pr-10 py-3 text-left w-full rounded-md hover:ring-1 hover:border-2 hover:border-brand-100
        focus:border-2 focus:outline-none focus:ring-1 focus:border-brand-100 focus:ring-brand-100 placeholder-ink-40`,
        selected ? 'text-ink-60' : 'text-ink-40',
        error ? 'border-red-500' : ''
    );

    return (
        <Listbox value={selected} onChange={setValue}>
            {({ open }) => (
                <div className={'space-y-1'}>
                    <Listbox.Label className="text-sm text-ink-60">{label} {markRequired ? '*' : ''}</Listbox.Label>
                    <div className="mt-1 relative">
                        <Listbox.Button className={classNames(inputClassNames, open ? 'border-2 border-brand-100' : 'border border-ink-20')}>
                            <span className="block truncate">{selected ? selected.name : placeholder}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <DownArrowIcon className={classNames('h-6 w-6 group-focus:text-brand-100', open ? 'text-brand-100' : 'text-ink-60')} aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-ink-60 ring-1 ring-brand-100 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-brand-5' : 'text-ink-60',
                                                'cursor-pointer select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={option}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {option.name}
                                            </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

export default FormSelectField;
