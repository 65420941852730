import React, { FunctionComponent } from 'react';
import {Form, Formik} from "formik";
import FormField from "../components/FormField";
import Button from "../components/Button/Button";
import * as Yup from "yup";
import FormSelectField from "../components/FormSelectField";

type OrderFormValues = {
    email: string,
    fullName: string,
    phone: string,
    country: string,
    request: string,
};

const OrderFormValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    phone: Yup.string().matches(/^(\+[1234]{3})([\d]{9})$/).required('Required'),
    request: Yup.string().required('Required')
});

const countries = [
    { id: 'sk', name: 'Slovakia'},
    { id: 'cz', name: 'Czech Republic'},
    { id: 'hu', name: 'Hungary'},
    { id: 'at', name: 'Austria'},
];

const OrderForm: FunctionComponent = () => {
    const initialValues: OrderFormValues = {
        email: '',
        fullName: '',
        phone: '',
        country: null,
        request: '',
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={OrderFormValidation}
                onSubmit={(values: OrderFormValues) => {
                    // same shape as initial values
                    console.log(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={'space-y-6 mb-6'}>
                            <FormField name="email" type="text" label="Email address" markRequired placeholder={"name@address.com"}/>
                            <FormField name="fullName" type="text" label="Full name" markRequired placeholder={"David Johanson"}/>
                            <FormField
                                name="phone"
                                type="text"
                                label="Phone number"
                                placeholder={"+421 000 000 000"}
                                hint={'Please use your country prefix.'}
                                markRequired
                            />
                            <FormSelectField
                                name="country"
                                label={'Country'}
                                markRequired
                                options={countries}
                            />

                            <FormField name="request" type="textarea" label="Message" markRequired placeholder={"Your text..."}/>
                        </div>
                        <Button type="submit" variant={'secondary'}>Send the form</Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OrderForm;
